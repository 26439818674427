import { useEffect, useState } from "react";
import "./App.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    const [domain, setDomain] = useState("");
    const { search } = typeof window !== "undefined" ? window.location : {};
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(()=>{
        if(!search.includes("shop")){
            setIsDisabled(true);
            toast.warn('Invalid shop!', {
                position: "top-right",
                });
        }
    },[search])

    const createIntegration = () => {
        window.location.href = `https://${domain}.trackier.io${search}`;
    };

    console.log(process.env.NODE_ENV)
    return (
        <div className="primary-container">
            <div className="primary-box">
                <div className="shopify-icon-box">Trackier + Shopify</div>
                <div>
                    <div className="d-flex align-items-center">
                        <div>
                            <p className="title-new-trackier">
                                New to Trackier:
                            </p>
                            <h2 className="heading">
                                Want to launch a Partner Program?
                            </h2>
                            <p className="desc">
                                Choose a better Partner Marketing Platform for
                                driving performance from every partnership. See
                                it in action and request free trial access.
                            </p>
                        </div>

                        <a
                            href="https://trackier.com/request-demo/"
                            className="request-demo">
                            Request Demo
                        </a>
                    </div>
                    <p className="title-new-trackier">
                        Existing Trackier Users:
                    </p>
                    <h2 className="heading">
                        Connect your Shopify Store to an Trackier account
                    </h2>

                    <p className="desc">
                        Input the Trackier network identifier
                    </p>
                    <div className="input-group mb-3">
                        <input
                            id="shop"
                            name="shop"
                            type="hidden"
                            value="quickstart-4d5b2102.myshopify.com"
                        />
                        <input
                            id="code"
                            name="code"
                            type="hidden"
                            value="c7e179c00c5ce01fd858fbb2e7e91595"
                        />
                        <div className="input-group-prepend">
                            <span
                                className="input-group-text"
                                id="basic-addon1">
                                https://
                            </span>
                        </div>
                        <input
                            id="network_identifier"
                            name="network_identifier"
                            type="text"
                            className="form-control"
                            required=""
                            onChange={({ target }) => setDomain(target.value)}
                            placeholder="Trackier Domain"
                            aria-label="Network Identifier"
                            aria-describedby="network_idenfitier_label"
                        />
                        <div className="input-group-append">
                            <span
                                className="input-group-text"
                                id="network_idenfitier_label">
                                .trackier.io
                            </span>
                        </div>
                    </div>

                    <button
                        className="request-demo"
                        disabled={isDisabled}
                        onClick={createIntegration}>
                        Complete Installation
                    </button>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default App;
